<template>
  <div class="v-stack h-stretch" v-if="item">
    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="content mt-3">
          <div class="v-stack h-stretch gap-5">
            <div>Are you sure you want to delete this item?</div>
          </div>
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismiss()" class="submit">Cancel</button>
          <button @click="remove()" class="edit">Delete</button>
        </div>
      </div>
    </div>

    <div class="card light">
      <div class="content h-stretch">
        <form class="gap-3 h-stretch">
          <label class="text-left">Date of Purchase:</label>
          <InputDatePicker v-model="item.dateOfPurchase"></InputDatePicker>
          <label class="text-left">Store:</label>
          <input type="text" v-model="item.storeDescription" />
          <label class="text-left">Serial Number:</label>
          <input type="text" v-model="item.serialNumber" />
          <label class="text-left">Cost:</label>
          <input type="number" v-model="item.value" />
          <label class="text-left">Description:</label>
          <input type="text" v-model="item.description" />
          <div class="h-stack h-space-between">
            <button @click.prevent="removeDialog()" class="edit">Delete</button>
            <button @click.prevent="submit()" class="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  props: ["id"],
  data() {
    return {
      item: null,
    };
  },
  components: {
    InputDatePicker,
  },
  methods: {
    ...mapActions(["editGearItem", "fetchGearItem", "removeGearItem"]),
    submit() {
      this.editGearItem(this.item)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeDialog() {
      this.$refs.overlay.classList.add("show");
    },
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
    remove() {
      this.removeGearItem(this.id)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
      this.dismiss();
    },
  },
  mounted() {
    this.fetchGearItem(this.id)
      .then((item) => {
        this.item = item;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
